import styled from '@mui/system/styled';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
//import { useTheme } from '@emotion/react';
import useTheme from '@mui/system/useTheme';
import Button from '@mui/base/Button';

const OptionButtonContainer = styled(Button)`
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.palette.colors.primary1};
    border-radius: 12px;
    cursor: pointer;
    height: 32px;
    width: 32px;
    color: ${({ theme }) => theme.palette.colors.text1};
    border: none;
    outline: none;
    transition: background-color 125ms ease-in 0s;
    &:hover {
        background-color: ${({ theme }) => theme.palette.colors.primaryTransparent1};
    }
    &:active {
        background-color: ${({ theme }) => theme.palette.colors.white};
    }
`;

const OptionButton = ({ type, onClick }) => {
    const theme = useTheme();

    return (
        <OptionButtonContainer onClick={onClick}>
            {type === "power" && <PowerSettingsNewIcon sx={{ color: theme.palette.colors.text2 }} fontSize="small" />}
            {type === "settings" && <SettingsOutlinedIcon onClick={onClick} sx={{ color: theme.palette.colors.text2 }} fontSize="small" />}
        </OptionButtonContainer>
    );
};

export default OptionButton;