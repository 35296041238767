import React, { useEffect, useState, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import ThemeProvider from '@mui/material/styles/ThemeProvider';
import styled from '@mui/system/styled';
import Box from '@mui/material/Box';

import { KeplrProvider } from './hooks/useKeplr';
import { AccountDrawerProvider } from './hooks/useAccountDrawer';
import { StackDataProvider } from './hooks/useFetchStack';
import { MobileDeviceDetectProvider, MobileDeviceDetectContext } from './hooks/useMobileDeviceDetect';

import AccountDrawer from './components/AccountDrawer';
import Navbar from './components/Navbar';
import WarningBanner from './components/WarningBanner';
import HelpButton from './components/HelpButton';

import { lightTheme, darkTheme } from './themes/theme';

// Lazy load pages
const Launch = React.lazy(() => import('./pages/Launch'));
const Chainlets = React.lazy(() => import('./pages/Chainlets'));
const ChainletDetail = React.lazy(() => import('./pages/ChainletDetail'));
const NotFoundPage = React.lazy(() => import('./pages/NotFoundPage'));


const Background = styled(Box)`
  background: radial-gradient(100% 100% at 50% 0%, ${({ theme }) => theme.palette.colors.backgroundGradient2} 0%, ${({ theme }) => theme.palette.colors?.backgroundGradient1} 100%), ${({ theme }) => theme.palette.colors?.white};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  width: 200vw;
  height: 200vh;
  transform: translate(-50vw,-100vh);
  z-index: -1;
`;

// Using custom useTheme hook to swap themes used by MUI ThemeProvider - which then allows MUI useTheme hook throughout app
// TODO: Make less confusing due to 2 useTheme hooks being referenced in the app
const useTheme = () => {
  const [themeType, setThemeType] = useState("light");

  useEffect(() => {
    const savedTheme = window.localStorage.getItem('theme') || 'light';
    setThemeType(savedTheme);
    window.localStorage.setItem('theme', savedTheme);
  }, []);

  const toggleThemeType = () => {
    const newThemeType = themeType === 'light' ? 'dark' : 'light';
    window.localStorage.setItem('theme', newThemeType);
    setThemeType(newThemeType);
  };

  return [themeType, toggleThemeType];
};

const App = () => {
  const [themeType, toggleThemeType] = useTheme();

  const [isServiceAvailable, setIsServiceAvailable] = useState(true);

  const checkServiceAvailability = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_RPC_URL}/status?`);
      setIsServiceAvailable(response.ok);
    } catch (error) {
      console.error('Error checking service availability:', error);
      setIsServiceAvailable(false);
    }
  };

  useEffect(() => {
    checkServiceAvailability();
  }, []);

  return (
    <MobileDeviceDetectProvider>
      <StackDataProvider>
        <KeplrProvider>
          <ThemeProvider theme={themeType === 'light' ? lightTheme : darkTheme}>
            <Router>
              <AccountDrawerProvider>
                <Navbar />
                <AccountDrawer toggleThemeType={toggleThemeType} themeType={themeType} />
                <Suspense>
                  {<MobileDeviceDetectContext.Consumer>
                    {
                      (ctx) => {
                        if (ctx.isMobile !== undefined) {
                          return (ctx.isMobile ?
                            <Routes>
                              <Route index element={<Navigate replace to={"/chainlets"} />} />
                              <Route path="/chainlets" element={<Chainlets />} />
                              <Route path="/chainlets/:chainId" element={<ChainletDetail />} />
                              <Route path="*" element={<NotFoundPage />} />
                            </Routes>
                            :
                            <>
                            <Routes>
                              <Route index element={<Navigate replace to={"/launch"} />} />
                              <Route path="/chainlets" element={<Chainlets />} />
                              <Route path="/launch" element={<Launch />} />
                              <Route path="/chainlets/:chainId" element={<ChainletDetail />} />
                              <Route path="*" element={<NotFoundPage />} />
                            </Routes>
                            <HelpButton />
                            </>
                          )
                        }
                      }
                    }
                  </MobileDeviceDetectContext.Consumer>}
                </Suspense>
                <Background />
                {!isServiceAvailable && <WarningBanner />}
              </AccountDrawerProvider>
            </Router>
          </ThemeProvider>
        </KeplrProvider>
      </StackDataProvider>
    </MobileDeviceDetectProvider>
  );
};

export default App;